
import { Component, Vue } from 'vue-property-decorator'
import MobioPageDescription from '../../../components/MobioPageDescription.vue';
import { namespace } from 'vuex-class';
import MobioLogo from '../../../components/MobioLogo.vue';

const app = namespace('app');

@Component({
  components: {
    MobioPageDescription,
    MobioLogo
  }
})
export default class RegisterSent extends Vue {
  @app.State('isApp') isApp: boolean;

  description() {
    return (this.$t('description1') as string).replace('[profile.newEmail]', this.cleanEmailAddress);
  }

  onBack() {
    this.$router.push('/');
  }

  clean(text: string): string {
    if (
      !text ||
      (typeof text !== 'string')
    ) {
      return '';
    }

    const result = decodeURIComponent(text || '');

    return result
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#x27;')
      .replace(/\//g, '&#x2F;');
  }

  get cleanEmailAddress() {
    return this.clean(this.$route.query.email as string);
  }
}
